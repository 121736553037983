<template>
    <div class="allOrder" style="text-align: left;">
        <van-nav-bar title="全部订单" left-text="返回" left-arrow @click-left="onReturn">
        </van-nav-bar>
        <div class="top" style="padding: 20px 0 15px 10px;background: white;">
            <div style="width: 84%;float: left;font-size: 22px;">
                <span>{{searchShowText}}</span>
            </div>
            <div style="width: 16%;float: right;text-align: right;">
                <van-button @click="doSelectSearch" :icon="icon_shaixuan" color="#7232dd" plain
                    style="height: 38px;background-color:transparent;padding:0 10px 0 0;">筛选</van-button>
            </div>
            <div style="clear: both;"></div>
        </div>
        <div class="all-amount" style="padding: 10px;background: white;">
            <div style="width: 55%;float: left;font-size: 16px;">
                <span>订货金额：</span><span style="color: #e24210;">￥{{ countPayAmount }}</span>
            </div>
            <div style="width: 45%;float: right;text-align: right;font-size: 16px;">
                <span>佣金金额：</span><span style="color: #e24210;">￥{{ countCommission }}</span>
            </div>
            <div style="clear: both;"></div>
        </div>

        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" v-if="!isDataEmpty" @load="onLoad">
            <van-cell v-for="(item,index) in list" :key="index" :title="item.createTime" :icon="icon_todo_date" is-link
                :to="'orderDetail?orderNo='+item.orderNo" style="margin-top: 10px;font-size: 15px;">
                <template #label>
                    <van-icon :name="icon_huanhuo" v-if="item.orderType == 4 || item.orderType == 5" />
                    <span style="font-size: 18px;color: #565555;font-weight: 600;">{{item.title}}</span>
                    <van-button v-if="roleKbn == 0 && item.orderStatus == 5" :color="!item.payImage?'#5f0bf5':'#696666'"
                        plain size="small" style="float: right;font-size: 14px;height: 26px;"
                        @click="uploadPayImg(item.orderNo, item.payImage)">支付凭证</van-button>
                    <div style="margin-top: 5px;font-size: 15px;"><span>{{item.orderNo}}</span><span
                            style="display: inline-block;float: right;">{{item.orderStatus | orderStatusFilter}}</span>
                    </div>
                </template>
            </van-cell>
        </van-list>
        <van-empty description="没有数据哦" v-if="isDataEmpty" />

        <van-popup class="pickerPopup" v-model="showPickerFlag" closeable position="bottom" :style="{ height: 'auto' }">
            <van-tabs v-model="tabActive">
                <van-tab title="月份选择">
                    <van-datetime-picker v-model="currentDate" type="year-month" :show-toolbar="showPickerToolbar"
                        :min-date="minDate" :max-date="maxDate" :formatter="formatter" />
                </van-tab>
                <van-tab title="自定义">
                    <van-button plain :class="dateBtnSelectedKbn==2?'active':''" type="primary" size="small"
                        style="width: 80px;margin: 10px 0 10px 15px;" @click="fastChangeSearchDate(2);">上月</van-button>
                    <van-button plain :class="dateBtnSelectedKbn==3?'active':''" type="info" size="small"
                        style="width: 80px;margin: 10px 0 10px 15px;" @click="fastChangeSearchDate(3);">近三月</van-button>
                    <van-button plain :class="dateBtnSelectedKbn==4?'active':''" type="primary" size="small"
                        style="width: 80px;margin: 10px 0 10px 15px;" @click="fastChangeSearchDate(4);">近一年</van-button>
                    <van-cell title="自定义" />
                    <div>
                        <div id="btnSelectedDate1" :class="dateBtnSelectedKbn==0?'active':''"
                            style="margin: 10px 0 10px 15px;float:left;" @click="changeSelectedDateKbn(0);">
                            {{beginDateTmp}}</div>
                        <div
                            style="color: rgb(177 171 171);height: 30px;line-height: 30px;float: left;margin: 10px auto;width: calc(20% - 34px);text-align: center;">
                            ---</div>
                        <div id="btnSelectedDate2" :class="dateBtnSelectedKbn==1?'active':''"
                            style="margin: 10px 15px 10px 0;float:right;" @click="changeSelectedDateKbn(1);">
                            {{endDateTmp}}</div>
                    </div>
                    <div style="clear: both;"></div>
                    <van-datetime-picker v-model="currentDate2" type="date" :readonly="datePickerReadonly"
                        :show-toolbar="showPickerToolbar" :min-date="minDate" :max-date="maxDate"
                        @change="currentDate2Change" />
                </van-tab>
            </van-tabs>
            <van-button type="info" block style="margin: 0 15px 10px;width: calc(100% - 30px);" @click="confirmDate">确认
            </van-button>
        </van-popup>

        <van-dialog class="payImgUploader" v-model="showPayImgUploader" title="上传支付凭证" show-cancel-button
            confirmButtonText="确认上传" @confirm="confirmUploadPayImg">
            <van-uploader v-model="payImgShowFile" max-count="1" :after-read="afterImgFileRead" deletable
                :before-delete="beforeImgFileDelete" />
        </van-dialog>
    </div>
</template>

<script>
    import icon_shaixuan from "@/assets/icon_shaixuan.png";
    import icon_todo_date from "@/assets/icon_todo_date.png";
    import icon_huanhuo from "@/assets/icon_huanhuo.png";
    import {
        countPriceAndCommission,
        getOrderGoodsList,
        ossUpload,
        uploadPayImage
    } from "@/api/order";
    import { Toast } from "vant";

    export default {
        name: "AllOrder",
        filters: {
            orderStatusFilter(val) {
                // 0待审核1订单取消2审核通过3审核驳回4确认送货5确认收货6送货超时
                const statusMap = {
                    "0": "待审核",
                    "1": "订单取消",
                    "2": "审核通过",
                    "3": "审核驳回",
                    "4": "确认送货",
                    "5": "确认收货",
                    "6": "送货超时"
                };
                return statusMap[val];
            }
        },
        data() {
            return {
                icon_shaixuan: icon_shaixuan,
                icon_todo_date: icon_todo_date,
                icon_huanhuo: icon_huanhuo,
                countPayAmount: 0,
                countCommission: 0,
                roleKbn: null,
                list: [],
                loading: false,
                finished: false,
                pageNo: 0,
                pageSize: 10,
                searchShowText: "",
                beginDate: "",
                endDate: "",
                beginDateTmp: "",
                endDateTmp: "",
                isDataEmpty: false,
                showPickerToolbar: false,
                showPickerFlag: false,
                minDate: new Date(2020, 0, 1),
                maxDate: new Date(),
                currentDate: new Date(),
                currentDate2: null,
                tabActive: 0,
                dateBtnSelectedKbn: 0,
                datePickerReadonly: false,
                showPayImgUploader: false,
                payImgShowFile: [],
                payImgOrderNo: null,
                payImgUploadFile: null,
                uploadedPayImgs: {}
            };
        },
        created() {
            let role = localStorage.getItem("role");
            if (role == "region_manager") {
                this.roleKbn = 0;
            } else if (role == "terminator") {
                this.roleKbn = 1;
            }
            let initParams = this.getFirstDayAndLastDayOfMonth();
            this.fetchData(initParams);
            this.beginDateTmp = this.beginDate;
            this.endDateTmp = this.endDate;
            this.currentDate2 = new Date(this.beginDateTmp);
        },
        methods: {
            fetchData(initParams) {
                // let initParams = this.getFirstDayAndLastDayOfMonth();
                // console.log(initParams);
                this.searchShowText = initParams.showText;
                this.beginDate = initParams.firstDay;
                this.endDate = initParams.lastDay;
                let params = { beginDate: this.beginDate, endDate: this.endDate };
                countPriceAndCommission(params).then(response => {
                    // console.log(response);
                    this.countPayAmount = response.data.countPayAmount;
                    this.countCommission = response.data.countCommission;
                });
            },
            onLoad() {
                this.pageNo++;
                let params = {
                    beginDate: this.beginDate,
                    endDate: this.endDate,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize
                };
                getOrderGoodsList(params).then(response => {
                    console.log(response.data);
                    let data = response.data;
                    // 加载状态结束
                    this.loading = false;
                    // 没有数据
                    if (data.total == 0) {
                        this.isDataEmpty = true;
                        this.finished = true;
                    } else {
                        for (let i in data.records) {
                            let item = data.records[i];
                            this.list.push({
                                id: item.id,
                                createTime: item.createTime.replace("T", " "),
                                title: item.title,
                                orderNo: item.orderNo,
                                orderType: item.orderType,
                                payImage: item.payImage,
                                orderStatus: item.orderStatus
                            });
                        }
                        if (data.current == data.pages) {
                            // 数据全部加载完成
                            this.finished = true;
                        }
                    }
                });
            },
            getFirstDayAndLastDayOfMonth() {
                let date = new Date(),
                    y = date.getFullYear(),
                    m = date.getMonth();
                let firstDay = new Date(y, m, 1);
                //let lastDay = new Date(y, m + 1, 0);
                let lastDay = new Date();
                let showText = y + "年" + (m + 1) + "月";
                return {
                    firstDay: this.formatDate(firstDay),
                    lastDay: this.formatDate(lastDay),
                    showText: showText
                };
            },
            getFirstDayAndLastDay(date) {
                let y = date.getFullYear(),
                    m = date.getMonth();
                // 是否当月
                let date2 = new Date(),
                    y2 = date2.getFullYear(),
                    m2 = date2.getMonth();
                if (y == y2 && m == m2) {
                    return this.getFirstDayAndLastDayOfMonth();
                } else {
                    let firstDay = new Date(y, m, 1);
                    let lastDay = new Date(y, m + 1, 0);
                    let showText = y + "年" + (m + 1) + "月";
                    return {
                        firstDay: this.formatDate(firstDay),
                        lastDay: this.formatDate(lastDay),
                        showText: showText
                    };
                }
            },
            formatDate(date) {
                let Y = date.getFullYear();
                let M = date.getMonth() + 1;
                let D = date.getDate();
                return Y + (M < 10 ? "-0" : "-") + M + (D < 10 ? "-0" : "-") + D;
            },
            doSelectSearch() {
                this.showPickerFlag = true;
            },
            formatter(type, val) {
                if (type === "year") {
                    return `${val}年`;
                } else if (type === "month") {
                    return `${val}月`;
                }
                return val;
            },
            changeSelectedDateKbn(kbn) {
                this.dateBtnSelectedKbn = kbn;
                this.datePickerReadonly = false;
                if (kbn == 0) {
                    this.currentDate2 = new Date(this.beginDateTmp);
                } else if (kbn == 1) {
                    this.currentDate2 = new Date(this.endDateTmp);
                }
            },
            fastChangeSearchDate(type) {
                this.dateBtnSelectedKbn = type;
                if (type == 2) {
                    // 上月
                    let date = new Date(),
                        y = date.getFullYear(),
                        m = date.getMonth();
                    if (m == 0) {
                        y = y - 1;
                    } else {
                        m = m - 1;
                    }
                    let firstDay = new Date(y, m, 1);
                    let lastDay = new Date(y, m + 1, 0);
                    this.beginDateTmp = this.formatDate(firstDay);
                    this.endDateTmp = this.formatDate(lastDay);
                    this.datePickerReadonly = true;
                } else if (type == 3) {
                    // 近三月
                    let date = new Date();
                    date.setMonth(date.getMonth() - 3);
                    let firstDay = date;
                    let lastDay = new Date();
                    this.beginDateTmp = this.formatDate(firstDay);
                    this.endDateTmp = this.formatDate(lastDay);
                    this.datePickerReadonly = true;
                } else if (type == 4) {
                    // 近一年
                    let date = new Date();
                    date.setFullYear(date.getFullYear() - 1);
                    let firstDay = date;
                    let lastDay = new Date();
                    this.beginDateTmp = this.formatDate(firstDay);
                    this.endDateTmp = this.formatDate(lastDay);
                    this.datePickerReadonly = true;
                }
            },
            confirmDate() {
                // 月份选择
                if (this.tabActive == 0) {
                    console.log(this.currentDate);
                    this.fetchData(this.getFirstDayAndLastDay(this.currentDate));
                    this.list = [];
                    this.pageNo = 0;
                    this.isDataEmpty = false;
                    this.loading = false;
                    this.finished = false;
                    this.showPickerFlag = false;
                } else {
                    // 自定义
                    if (this.beginDateTmp > this.endDateTmp) {
                        Toast.fail("结束日期不能比开始日期小.");
                    } else {
                        let showText =
                            this.formateYMD(this.beginDateTmp) +
                            "-" +
                            this.formateYMD(this.endDateTmp);
                        let initParams = {
                            firstDay: this.beginDateTmp,
                            lastDay: this.endDateTmp,
                            showText: showText
                        };
                        this.fetchData(initParams);
                        this.list = [];
                        this.pageNo = 0;
                        this.isDataEmpty = false;
                        this.loading = false;
                        this.finished = false;
                        this.showPickerFlag = false;
                    }
                }
            },
            currentDate2Change() {
                console.log(this.currentDate2);
                if (this.dateBtnSelectedKbn == 0) {
                    this.beginDateTmp = this.formatDate(this.currentDate2);
                } else if (this.dateBtnSelectedKbn == 1) {
                    this.endDateTmp = this.formatDate(this.currentDate2);
                }
            },
            formateYMD(ymd) {
                return (
                    ymd.substring(0, 4) +
                    "年" +
                    ymd.substring(5, 7) +
                    "月" +
                    ymd.substring(8, 10) +
                    "日"
                );
            },
            uploadPayImg(orderNo, payImage) {
                event.stopPropagation();
                console.log(orderNo, payImage, this.uploadedPayImgs[orderNo]);
                this.payImgOrderNo = orderNo;
                this.payImgUploadFile = null;
                this.payImgShowFile = [];
                if (this.uploadedPayImgs[orderNo]) {
                    this.payImgShowFile.push({
                        url: this.uploadedPayImgs[orderNo]
                    });
                } else if (payImage) {
                    this.payImgShowFile.push({ url: payImage });
                }
                this.showPayImgUploader = true;
            },
            afterImgFileRead(file) {
                console.log(file);
                this.payImgUploadFile = file;
                return true;
            },
            beforeImgFileDelete(file) {
                console.log(file);
                return true;
            },
            confirmUploadPayImg() {
                if (this.payImgUploadFile) {
                    // 先上传图片至OSS
                    ossUpload(this.payImgUploadFile.file).then(r => {
                        console.log(r.data);
                        // 提交上传
                        uploadPayImage(this.payImgOrderNo, r.data).then(r2 => {
                            console.log(r2);
                            this.uploadedPayImgs[this.payImgOrderNo] = r.data;
                        });
                    });
                }
            },
            onReturn() {
                this.$router.go(-1);
            }
        }
    };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .pickerPopup .van-button--plain {
        border-color: gray !important;
        color: #222222 !important;
        font-size: 15px;
    }
    .van-button--plain.active {
        border-color: #0d55d7 !important;
    }
    .top .van-button--plain {
        border-color: transparent !important;
        color: #222222 !important;
        font-size: 15px;
    }
    .van-cell__value {
        color: #323233;
    }
    .van-cell__label {
        margin-top: 8px;
    }
    #btnSelectedDate1,
    #btnSelectedDate2 {
        width: 40%;
        background: #e9e5e5;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 20px;
    }
    #btnSelectedDate1.active,
    #btnSelectedDate2.active {
        border: 1px solid #0d55d7;
    }
</style>
